<template>
  <div class="panel--steps">
    <div class="container">
      <v-card class="mt-3 checkout--card">
        <div class="clearfix mb-20"></div>
        <div class="tabs--container">
          <v-tabs class="panel--tabs" v-model="tab" center-active>
            <v-tab
              :href="`#tab-${index}`"
              v-for="(contentTab, index) in content.tabs"
              :key="`step-tab-${index}`"
            >
              {{ contentTab.title }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              :value="`tab-${index}`"
              v-for="(contentTab, index) in content.tabs"
              :key="`step-tab-content-${index}`"
            >
              <div
                class="single--step"
                v-for="(step, stepIndex) in contentTab.steps"
                :key="`step-${stepIndex}`"
              >
                <div class="left--side">
                  {{ stepIndex + 1 }}
                </div>
                <div class="right--side">
                  {{ step }}
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div class="bottom--button">
          <router-link :to="viewMybookingUrl" class="btn btn-primary"
            >{{ $t('general.btn.viewMyOrder') }}
          </router-link>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'panel-steps',
  props: ['content', 'viewMybookingUrl'],
  data() {
    return {
      tab: 'tab-0',
    };
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/utils/variables';
.tabs--container {
  border-radius: 30px;
  padding: 24px 36px;
  border: solid 1px $color-text-gray-1;
  box-shadow: $box-shadow;
}
.panel--steps {
  .panel--tabs {
    border-radius: 30px;
    border: solid 1px $color-text-gray-1;
    @media #{$phones} {
      border-radius: 24px;
    }
    .nav-tabs {
      flex-wrap: nowrap;
      padding-left: 0;
      border-bottom: solid 1px $color-text-gray-1;
      background-color: $color-beliruma-1-light;
      border-radius: 30px 30px 0 0;
      @media #{$phones} {
        border-radius: 24px 24px 0 0;
      }
    }
    .nav-item {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-grow: 1;
      .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 0;
        border-left: none;
        border-top: none;
        border-right: solid 1px $color-text-gray-1;
        border-bottom: solid 1px $color-text-gray-1;
        padding: 12px 28px;
        font-family: Poppins-Bold;
        text-align: center;
        color: $color-beliruma-1-dark-alt;
        background-color: $color-beliruma-1-light;
        @media #{$phones} {
          font-size: $font-xs;
          padding: 8px 12px;
          word-break: break-word;
        }
        &.active {
          color: $color-beliruma-1;
          background-color: $color-white;
        }
      }
      &:first-child {
        .nav-link {
          border-top-left-radius: 30px;
          @media #{$phones} {
            border-top-left-radius: 24px;
          }
        }
      }
      &:last-child {
        .nav-link {
          border-top-right-radius: 30px;
          border-right: none;
          @media #{$phones} {
            border-top-right-radius: 24px;
          }
        }
      }
    }
    .tab-content {
      padding: 12px 36px;
      @media #{$phones} {
        padding: 8px 16px;
      }
    }
  }
  .single--step {
    display: flex;
    align-items: stretch;
    border: solid 1px $color-text-gray-1;
    border-radius: 18px;
    margin: 24px 0;
    @media #{$phones} {
      margin: 16px 0;
    }
    .left--side {
      display: flex;
      justify-content: center;
      min-width: 60px;
      font-family: Poppins-Bold;
      font-size: $font-lg;
      background-color: $color-gray-1;
      padding: 8px 0;
      border-radius: 18px 0 0 18px;
      border-right: solid 1px $color-text-gray-1;
      @media #{$phones} {
        font-size: $font-md;
        padding: 6px 0;
      }
    }
    .right--side {
      font-family: Poppins-SemiBold;
      font-size: $font-ml;
      padding: 10px 16px;
      border-radius: 0 18px 18px 0;
      @media #{$phones} {
        font-size: $font-xs;
        padding: 8px 12px;
      }
    }
  }
  .bottom--button {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
</style>
